import React, { useState, useEffect, useRef, useCallback } from "react";
import BrigoshaIcon from "../../assets/brigoshaIconhiring.svg"
import homeIcon from "../../assets/homeiconhiring.svg"
import {isMobile} from 'react-device-detect';
import { HiVideoCameraSlash } from "react-icons/hi2";
import { IoMicOff } from "react-icons/io5";
import { HiVideoCamera } from "react-icons/hi2";
import { IoMic } from "react-icons/io5";
import { SECRET_KEY, decryptData } from "../../services/fileCode";
import { useNavigate } from "react-router-dom";
import { getUserJoined, postLandingInfo } from "./action";
import { useParams } from 'react-router-dom';
import { connectMedia } from "./scheduler";
import AskToJoinModal from "./askToJoin";
import conferenceStore from "./store";
import NotFound from "../NoData/noData";
import { Modal, Spin } from "antd";
import NotificationRequest from "../Notification/NotificationRequest";
import ChatClient from "./clientChat";

import { useLoginStore } from "../Login/store";
import SignInModal from "../Login/signInModal";
import { LoadingOutlined } from '@ant-design/icons';
import LandingCamera from "./landingCamera";
import ProfileIcon from "../../assets/profileIcon.svg"
import { EventRegister } from 'react-native-event-listeners';
/* import { useHistory } from "react-router"; */





const Header = ({ user, navigate, setModal }) => {
    const { setIsSignIn, setIsSignInModal, setCurPage } = useLoginStore(state => state)
    console.log(user, "userGObj");
    // user?.data?.show?.data?.payload?.authRole==="Associate"
    return (
        <>

            <div style={{ display: "flex", height: "10vh", width: "100%", borderBottom: "1px solid #5E5E5E", justifyContent: "space-between" }}>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", marginLeft: 20 }}>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <img src={BrigoshaIcon} />
                        <div>
                            <div style={{ fontSize: 20, color: "#1089FF", fontWeight: 600 }}>brigosha
                            </div>
                            <div style={{ fontSize: 14, color: "#5E5E5E" }}>Hiring management</div>
                        </div>
                    </div>
                </div>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div style={{ display: "flex", width: "70%", gap: "1rem", alignItems: "center" }}>
                        <img style={{ height: 25, width: 25, cursor: "pointer" }} src={homeIcon} onClick={() => {
                            if (user?.data?.show?.data?.payload?.hiringUser?.role?.includes("Agency") || user?.data?.show?.data?.payload?.hiringUser?.role?.includes("College-Admin")) {
                                navigate(`/auth-auth/checktoken?userId=${user?.data?.authUserLoginId}&token=new`)
                            } else if (user?.data?.show?.data?.payload?.authRole === "Associate") {
                                navigate("/homePage");
                            } else {
                                navigate("/login")
                            }
                        }} />
                        {
                            user?.isuserExist ?
                                <>
                                    <div style={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
                                        <img style={{ height: 32, width: 32, borderRadius: 15 }} src={user?.data?.profileImage || ProfileIcon} />
                                        <div>
                                            <div style={{ fontSize: 17, color: "#161616", fontWeight: 600, width: "10vw" }}>{user?.data?.name}
                                            </div>
                                            <div style={{ fontSize: 13, color: "#808080" }}>{user?.data?.authRole}</div>
                                        </div>
                                    </div>
                                </>
                                /*  <>
                                     <img style={{ height: 25, width: 25 }} src={notificationIcon} onClick={() => {
                                         if (user?.data?.show?.data?.payload?.hiringUser?.role?.includes("Agency") || user?.data?.show?.data?.payload?.hiringUser?.role?.includes("College-Admin")) {
                                             navigate(`/auth-auth/checktoken?userId=${user?.data?.authUserLoginId}&token=new`)
                                         } else if (user?.data?.show?.data?.payload?.authRole === "Associate") {
                                             setModal(true)
                                         } else {
                                         }
                                     }} />
                                    
                                 </> */
                                :
                                <>
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "8vw", height: "5vh", backgroundColor: "#1089FF", color: "white", borderRadius: 5, cursor: "pointer" }}
                                        onClick={() => {
                                            setIsSignIn(true);
                                            setIsSignInModal(true);
                                            setCurPage(5)
                                        }}
                                    >
                                        Sign in
                                    </div>
                                </>
                        }

                    </div>
                    {/*  {
                        user?.isuserExist &&
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <img style={{ height: 50, width: 50, padding: 10 }} src={arrowDown} />
                        </div>
                    }
 */}

                </div>
            </div>
        </>
    )
}



const VideoFooter = ({ isCameraOn, toggleCamera, toggleMic, isMicOn }) => {
    return (
        <>
            <div style={{ display: "flex", justifyContent: "space-between", gap: "1rem" }}>
                <div onClick={toggleCamera} style={{ cursor: "pointer", height: 50, width: 50, backgroundColor: isCameraOn ? "" : "red", borderRadius: 25, border: isCameraOn ? "1.5px solid white" : "", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    {
                        isCameraOn ?
                            <HiVideoCamera style={{ height: 20, width: 20 }} color="white" />
                            :
                            <HiVideoCameraSlash style={{ height: 20, width: 20 }} color="white" />
                    }
                </div>
                <div onClick={toggleMic} style={{ cursor: "pointer", height: 50, width: 50, backgroundColor: isMicOn ? "" : "red", borderRadius: 25, border: isMicOn ? "1.5px solid white" : "", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    {
                        isMicOn ?
                            <IoMic style={{ height: 20, width: 20 }} color="white" />
                            :
                            <IoMicOff style={{ height: 20, width: 20 }} color="white" />
                    }
                </div>
            </div>
        </>
    )
}

const ConferenceIndex = () => {
  
    const {setInitialVideoStream,mediaReady,updateActiveUserList,updatePanApprvModal, updateLocalStreamData,isMicOn, setIsMicOn, isCameraOn, setIsCameraOn, updateMeetIdG, meetIdG, updateUserG, userG, chatClient, setChatClient, updateActiveUser, setroundDetails,roundDetails,updateMediaReady } = conferenceStore(state => state);
    const { isSignIn } = useLoginStore(state => state);
    // const [isCameraOn, setIsCameraOn] = useState(false);
    // const [isMicOn, setIsMicOn] = useState(false);
    // const [activeUserList,updateActiveUserList]=useState();
    const [chatListUser,setChatUser]=useState([])
    const [stream, setStream] = useState(null);
    const [micStream, setMicStream] = useState(null);
    const [askToJoin, setJoin] = useState(false);
    const [openModal, setModal] = useState(false);
    const videoRef = useRef(null);
    const [load, setLoad] = useState(false)
    const [userJoined, setUserJoined]= useState([])
    const [visitorUid,setVisitUid]=useState("")
    const chatClientRef = useRef(null);

    let meetId = "";

    const navigate = useNavigate();
    const [user, setUser] = useState({
        isuserExist: false,
        userMail: "",
        data: null,
        allowtoJoin: false,
        info: "",
        loading: true,
    })

    const onCancel = () => {
        setModal(false);
    };

useEffect(() =>{
  
   
toggleCamera(false)
toggleMic()
},[])



    
        const getUserId = () => {
            let usersId = localStorage.getItem('deviceId');
            console.log("usersId",usersId);
            if (!usersId) {
              usersId = Math.floor(1000 + Math.random() * 9000);
              localStorage.setItem('deviceId', usersId);
            }
            return usersId;
          };
    useEffect(() => {
        const fpPromise = import('https://openfpcdn.io/fingerprintjs/v4')
    .then(FingerprintJS => FingerprintJS.load())

  // Get the visitor identifier when you need it.
  fpPromise
    .then(fp => fp.get())
    .then(result => {
      // This is the visitor identifier:
      const visitorId = result.visitorId
   
      setVisitUid(visitorId)
    })
        const queryString = window.location.pathname;
      
        meetId = queryString.split("brigosha/")[1];
        updateActiveUser([]);
        updateMeetIdG(meetId);
       
        let tt = localStorage.getItem('user');
       
        if (tt) {
            let userDetails = decryptData(tt, SECRET_KEY);
        
            if (userDetails) {
               let userData={}
                         userData={
                        name:userDetails?.name,
                        email:userDetails?.email,
                        phone:userDetails?.phone,
                        profileImage:userDetails?.profileImage,
                        id:parseInt(getUserId())
                    }
                        if(userDetails?.hiringUser){
                            userData["role"]=userDetails?.hiringUser?.role
                        }
                        setUser((prevUser) => ({
                                        ...prevUser,
                                        isuserExist: true,
                                        userMail: userData?.email,
                                        data: userData,
                                        loading: true,
                                        info: "",
                                        allowtoJoin: false,
                                        userId:parseInt(getUserId()),
                                        name:userData?.name
                                    }))
                
                updateUserG(userData);
          
                postLandingInfo({ roomId: meetId, email: userDetails?.email ||userData?.email, phone: userDetails?.phone||userData?.phone }, (res) => {
                    if (res?.type === "error") {
                        setUser((prevUser) => ({
                            ...prevUser,
                            loading: false,
                            info: "error",
                        }));
                    } else {
                        setUser((prevUser) => ({
                            ...prevUser,
                            loading: false,
                            allowtoJoin: res?.joinNow,
                            info: res?.info ? res?.info : "",
                            hostEmail:res?.hostEmail
                        }));
                        updatePanApprvModal(res?.joinNow)
                        setroundDetails(res)
                        if(isMobile){
                            window.location.href=res?.link
                        }
                        getUserJoined({ roomId: meetId }, (res) => { 
                             setUserJoined(res);
                         })
                    }
                });
            }
        }
        else{
            postLandingInfo({ roomId: meetId,  }, (res) => {
                if (res?.type === "error") {
                    setUser((prevUser) => ({
                        ...prevUser,
                        loading: false,
                        info: "error",
                    }));
                } else {
                    setUser((prevUser) => ({
                        ...prevUser,
                        loading: false,
                        info: "",
                    }));
                    updatePanApprvModal(res?.joinNow)
                    setroundDetails(res)
                    // getUserJoined({ roomId: meetId }, (res) => { 
                    //     // setUserJoined(res);
                    //  })
                }
            });
          
        }
    }, []);



    /*    const toggleCamera = async (isCameraOn) => {
           if (!isCameraOn) {
               try {
                   const newStream = await navigator.mediaDevices.getUserMedia({ video: true });
                   setStream(newStream);
                   if (videoRef.current) {
                       videoRef.current.srcObject = newStream;
                   }
                   setIsCameraOn(true);
               } catch (err) {
                   console.error('Error accessing the camera:', err);
               }
           } else {
               if (stream) {
                   setStream(null); // Corrected: setStream to null instead of false
                   const tracks = stream.getTracks();
                   tracks.forEach(track => track.stop());
                   if (videoRef.current) {
                       videoRef.current.srcObject = null;
                   }
                   setIsCameraOn(false);
               }
           }
       }; */
       
    const toggleCamera = async (isCameraOn) => {
   

        try {
            if (!isCameraOn) {
                const newStream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: 'user' } });
              
                setInitialVideoStream(newStream)
                 setStream(newStream);
                setIsCameraOn(true);
                // if (videoRef.current) {
                //     videoRef.current.srcObject = newStream;
                //     console.log('Video Ref:', videoRef.current);
                // }

                // setIsCameraOn(true);
            } else {
                if (stream) {
                    const tracks =await stream.getTracks();
                 
                    tracks.forEach((track) => {
                        if (track.kind === "video") {
                          if (track.enabled) {
                            track.stop();
                            track.enabled = false;
                          }
                        }
                      });
                    // tracks.forEach(track => track.stop());
           
                    if (videoRef.current) {
                        videoRef.current.srcObject = null;
                    }
                    // Set the stream to null
                     setStream(null);
                    setInitialVideoStream(null)
                    
                   
                    setIsCameraOn(false);
                }
            }
        } catch (err) {
            console.error('Error accessing the camera:', err.message, err.name);
        }
    };




    const toggleMic = async () => {

        if (!isMicOn) {
            try {
                const newMicStream = await navigator.mediaDevices.getUserMedia({ audio: true });
                setMicStream(newMicStream);
                setIsMicOn(true);
            } catch (err) {
                console.error('Error accessing the microphone:', err);
            }
        } else {
            if (micStream) {
                const tracks = micStream.getTracks();
                tracks.forEach(track => track.stop());
                setIsMicOn(false);
            }
        }
    };


const stopCam = async() =>{
    if (stream) {
        const tracks =await stream?.getTracks();

        if (tracks.length > 0) {
            tracks.forEach((track) =>   track.stop())
         
            
            // tracks.forEach(track => track.stop());
        } else {
            console.log('No tracks to stop.');
        }
 // tracks.forEach((track) => {
                        //     console.log('trackkkkkk',track);
                        //     if (track.kind === "video") {
                        //       if (track.enabled) {
                        //        track.stop();
                        //         track.enabled = false;
                        //       }
                        //     }
                        //   });
                        // tracks.forEach(track => track.stop());
        // Set the stream to null
 
}
}
useEffect(() =>{
    
if(mediaReady){
    stopCam()
//   if (micStream) {
//       const tracks = micStream.getTracks();
//       tracks.forEach(track => track.stop());
//         }
    setStream(null);
    // setTimeout(() =>{
    //     navigate("/room")
    // },600)
  
  setLoad(false) 
}
},[mediaReady])

    return (
        <>
            {
                !user?.loading ?
                    user?.info === "error" ?
                        <NotFound text={"meeting ended"} /> :
                        meetIdG != "" ?
                            <>
                                <Header user={user} navigate={navigate} setModal={setModal} />
                                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "2rem", marginTop: 10 }}>
                                    <div style={{ color: "#161616", fontSize: 20, fontWeight: 700 }}>
                                        
                                       
                                        {roundDetails?.info}
                                    </div>
                                    <div style={{display:"flex",flexDirection:"row",justifyContent:"space-around",width:"90%",marginTop:50}}>
                                    <div>
                                        {!isCameraOn ?

                                            <div style={{ width: '40vw', height: '50vh', backgroundColor: 'black', position: "relative", display: "flex", justifyContent: "center", borderRadius: 10 }}>
                                                <div style={{ position: "absolute", height: "8vh", width: "15vw", bottom: 30, display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                    <VideoFooter isMicOn={isMicOn} toggleMic={toggleMic} isCameraOn={isCameraOn} toggleCamera={() => {
                                                       
                                                        toggleCamera(isCameraOn)
                                                      
                                                    }} />

                                                </div>
                                                <div style={{ display: "flex", position: "absolute", color: "white", left: 10, top: 10, fontSize: 12, fontWeight: 300 }}>
                                                    You
                                                </div>
                                                <div style={{ display: "flex", position: "absolute", color: "white", top: 130, fontSize: 16, fontWeight: 300 }}>
                                                    Camera is off
                                                </div>
                                            </div>
                                            :
                                            <>
                                                <div style={{ display: "flex", position: "relative", justifyContent: "center" }}>
                                                    <div style={{height:"50vh",width:"40vw",borderRadius:10}}>
                                                 <LandingCamera stream={stream}/>
                                                 </div>
                                                    {
                                                        isCameraOn &&
                                                        <div style={{ position: "absolute", height: "8vh", width: "15vw", bottom: 30, display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                            <VideoFooter isMicOn={isMicOn} toggleMic={toggleMic} isCameraOn={isCameraOn} toggleCamera={() => {
                                                                
                                                                toggleCamera(isCameraOn)
                                                             
                                                            }} />
                                                        </div>
                                                    }
                                                    <div style={{ display: "flex", position: "absolute", color: "white", left: 10, top: 10, fontSize: 12, fontWeight: 300 }}>
                                                        You
                                                    </div>
                                                </div>
                                            </>
                                        }

                                    </div>


                                    <div style={{justifyContent:"center",display:"flex",flexDirection:"column",width:"40%",alignItems:"center"}}>
                                    
                                   
                                    {chatListUser.filter(ele =>ele?.privilege===visitorUid && ele?.occuranceId===2 && ele?.role.split("_")[0]===userG?.email && ele.statusToShow=="online").length>0  ?               
                                          <div> You are already in the meet with same device!</div>
                                          :

                                   <>
                                   {(!user?.isuserExist && !user?.allowtoJoin)&&<div style={{fontSize:24,marginBottom:6}}>Ready to Joint the Interview?</div> }
                                   {!askToJoin&&
                                        <div onClick={() => {
                                        if (user?.isuserExist && user?.allowtoJoin) {
                                           
                                            // stopCam()
                                           
                                            chatClientRef?.current?.disconnect()
                                            connectMedia(userG, meetIdG, navigate,updateActiveUserList,updateLocalStreamData,updateMediaReady)
                                            // setJoin(true)
                                        } else {
                                           
                                            setJoin(true)
                                            // connectMedia(userG, meetIdG, navigate)
                                        }
                                        setLoad(true)
                                       
                                    }} style={{ cursor: "pointer", gap: "0.2rem", display: "flex", width: "10vw", height: "5vh", backgroundColor: "#1089FF", color: "white", alignItems: "center", justifyContent: "center", borderRadius: 5 }}>
                                        {(user?.isuserExist && user?.allowtoJoin) ? "Join now" : "Ask to join"}
                                        {
                                            load ? <Spin indicator={<LoadingOutlined style={{ color: "white" }}></LoadingOutlined>}></Spin> : <></>

                                        }
                                    </div>}</>
                                    }
                                    
                                    {askToJoin&&
                                       <AskToJoinModal getUserId={getUserId}   onCancel={() => {setJoin(false)
                                         setLoad(false)
                                       }} onOk={() => { }} user={user} stopCam={stopCam} setChatUser={setChatUser} chatListUser={chatListUser} chatClientRef={chatClientRef} visitorUid={visitorUid} />
                                    }
                                    {user?.allowtoJoin &&
                                    <div style={{fontSize:14, fontWeight:400,color:"#5E5E5E", marginTop:10,justifyContent:"center",display:"flex",alignItems:"center"}}>
                                        
                                        {chatListUser?.filter(ele =>ele.statusToShow=="online"&&ele?.occuranceId==2)?.length==0 ? "Looks like you’re the first one here!" :
                                     chatListUser?.filter(ele =>ele.statusToShow=="online"&&ele?.occuranceId==2)?.map(ele =>(<> <img src={ele?.image || ProfileIcon} style={{height:20,width:20,borderRadius:10,marginRight:5}}/>{" "}{ele?.userName + ","}</>))}
                                       { chatListUser?.filter(ele =>ele.statusToShow=="online"&&ele?.occuranceId==2)?.length>0? chatListUser?.filter(ele =>ele.statusToShow=="online"&&ele?.occuranceId==2)?.length>1?" are here!":" is here!":""}
                                     </div>}
                                    </div>
                                    </div>
                                    {openModal && (
                                        <Modal
                                            open={openModal}
                                            onCancel={onCancel}
                                            centered
                                            width={"75vw"}
                                            bodyStyle={{ height: "90vh" }}
                                            footer={false}
                                        >
                                            <NotificationRequest setModal={setModal} />
                                        </Modal>
                                    )}
                                </div>
                            </>
                            :
                            <NotFound />
                    :
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", height: "90vh" }}>
                        <Spin />
                    </div>
            }
            {
                isSignIn && <SignInModal  afterSignin={(res)=>{console.log(res,'aftesigninsucess');}}/>
            }
        </>
    )
}

export default ConferenceIndex;