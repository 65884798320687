import React, { useState, useCallback, useRef } from 'react';
import { Modal, Form, Input, Button,Spin, } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { postGuestUserData } from './action';
import conferenceStore from './store';
import { useEffect } from 'react';
import ChatClient from './clientChat';
import { setUpChatSocket } from './utils';
import { connectMedia } from './scheduler';
import { useNavigate } from "react-router-dom";
import Notification from '../../services/Notification';

const AskToJoinModal = ({ getUserId, visible, onCancel, onOk, setChatUser, user, stopCam, chatClientRef, visitorUid, chatListUser, }) => {
    const { setChatSocketConnected, mediaReady, updateUserG, updateActiveUserList, updateLocalStreamData, updateMediaReady, roundDetails, updateMeetIdG, meetIdG, userG, mssgToken, chatClient, setChatClient, activeUserList } = conferenceStore(state => state)
    const [form] = Form.useForm();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [loader, setLoader] = useState(false)
    const [candidateEmail,setCandEmail]=useState()
     const [randomId, setRandomId] = useState(Math.floor(1000 + Math.random() * 9000))
    const [chatCon, setChatCon] = useState(false)
    const navigate = useNavigate();
    const chatListedUser = useRef([])
    // const [chatClient,setChatClient]= useState(null)

    const isFilled = (name, email, mobile) => {
        if ((name?.length && email?.length && mobile?.length)) {
            return false;
        } else {
            return true;
        }
    }
    useEffect(() => {
        if (mediaReady) {
            setLoader(false)
        }
        // setRandomId(generateRandomNumber())
        if (user?.data) {
            console.log("dssf", user);
            setName(user?.data?.name);
            setEmail(user?.data?.email);
            setMobile(user?.data?.phone)

        }



    }, [mediaReady])
    useEffect(() => {

        if (userG?.name) {
            console.log("entereddd", userG);
            chatClientRef.current = getClient(userG)
            setChatClient(chatClientRef.current)

        }
    }, [])
    // function generateRandomNumber() {
    //     return Math.floor(1000 + Math.random() * 9000);
    //   }


    const clientChatCallback = (e) => {
        console.log("eee", e);
        // if(e?.data?.type==="Users"){
        //     setUserJoined(e?.data?.data)
        //     return
        // }
        if (e?.event === "userList") {
            console.log("elele", e?.data);
            chatListedUser.current = e?.data
            setChatUser(e?.data)

        }
        // (userId===e?.data?.userId) 
        if (e?.data?.status === "deny") {
            Notification.error("Host denied entry")
            setLoader(false)
            // onCancel()
            if (!userG?.name) {
                chatClientRef?.current?.disconnect()
                setChatClient(null)
            }
            // chatClientRef?.current?.disconnect()
            // if (chatClientRef?.current) {
            //     chatClientRef?.current?.disconnect();
            //     chatClientRef.current = null;
            // }
            // window.location.reload()
        }
        if (e?.data?.status === "allow") {
            console.log("allowto join44", e, userG);
            Notification.success("Approved", "Joining in a moment,please wait")
            //  stopCam()
            chatClientRef.current.disconnect()
            setChatClient(null)
            if (user?.userId) {
                postGuestUserData({
                    "email": userG?.email,
                    "phone": userG?.phone,
                    "name": userG?.name,
                    "roomId": meetIdG
                }, () => {
                    // onCancel()
                })

                connectMedia(userG, meetIdG, navigate, updateActiveUserList, updateLocalStreamData, updateMediaReady)
            }
            else {
                let formData = form.getFieldsValue();
                let userData = { id: parseInt(randomId), name: formData?.name, email: formData?.email, phone: formData?.mobile }
                postGuestUserData({
                    "email": userData?.email,
                    "phone": userData?.phone,
                    "name": userData?.name,
                    "roomId": meetIdG
                }, () => {
                    // onCancel()
                })
                updateUserG(userData)
                connectMedia(userData, meetIdG, navigate, updateActiveUserList, updateLocalStreamData, updateMediaReady)

            }
        }
    }




    const getClient = useCallback((user) => {
        console.log("userss", user);
        let Id = user?.id;


        return new ChatClient(setUpChatSocket(setChatSocketConnected), Id, meetIdG, user, user?.name, visitorUid, `${userG?.email ||user?.email}`, user?.profileImage, "1", "4cb2509d-70f5-435e-8792-d24937743b53", "test.aulas.in", clientChatCallback)
    }, [])



    return (
        <div
            // title="Ask to join"
            // open={visible}
            // onCancel={() =>{
            //     setLoader(false)
            //     onCancel()}}
            style={{ textAlign: 'center', width: "80%", }}

            footer={null} // Remove the default footer
        >
            <div style={{ marginBottom: '16px', color: "#191919", fontSize: 14, fontWeight: 450 }}>
                <div>Before we welcome you into the session, could you please share a bit about yourself?</div>
            </div>
            <Form form={form} onFinish={(value) => {
                setLoader(true)
                let Id = userG?.id || parseInt(randomId)
                let userData = { id: Id, name: value?.name, email: value?.email, phone: value?.mobile }
                if (!userG?.name) {
                    chatClientRef.current = getClient(userData)
                    setChatClient(chatClientRef.current)
                }
                if(userData?.email.toLowerCase()===roundDetails.candidateEmail.toLowerCase()){
                    updateUserG(userData)
                    setCandEmail(true)
                    connectMedia(userData, meetIdG, navigate, updateActiveUserList, updateLocalStreamData, updateMediaReady)
                }
                else{
                setTimeout(() => {

                    if (chatListedUser.current.find(ele => ele?.occuranceId === 2 && ele?.role === roundDetails?.hostEmail && ele?.statusToShow === "online")) {
                        chatClientRef?.current?.sendGroupChat(Id, meetIdG,
                            {
                                type: 'Text',
                                data: null,
                                userId: Id,
                                roomId: meetIdG,
                                userName: name,
                                token: mssgToken,
                                sentOn: "",
                                askToJoin: true,
                                email: email,
                                mobile: mobile
                            }
                        )
                    }
                    else {
                        chatClientRef?.current?.addUser(Id, meetIdG,
                            {
                                type: 'Text',
                                data: null,
                                userId: Id,
                                roomId: meetIdG,
                                userName: name,
                                token: mssgToken,
                                sentOn: "",
                                askToJoin: true,
                                email: email,
                                mobile: mobile
                            }
                        )
                    }
                }, 1200)
            }



            }} layout="vertical">
                {console.log("conference user", userG)}
                <Form.Item
                    initialValue={userG?.name}
                    name={'name'}
                    label={<><span style={{ color: "#808080", fontSize: 12, fontWeight: 450 }}>Name</span></>}
                    style={{ textAlign: 'center' }}
                    rules={[
                        { required: true, type: "string", message: 'Please enter a valid name' },
                    ]}
                >
                    <Input
                        disabled={userG?.name}
                        placeholder="Name"
                        // value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                </Form.Item>
                <Form.Item
                    initialValue={userG?.email}
                    name={'email'}
                    label={<><span style={{ color: "#808080", fontSize: 12, fontWeight: 450 }}>Email ID</span></>}
                    rules={[
                        { required: true, type: 'email', message: 'Please enter a valid email' },
                    ]}
                >
                    <Input
                        disabled={userG?.email}
                        placeholder="Email ID"
                        // value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </Form.Item>
                <Form.Item
                    initialValue={userG?.phone}
                    name={'mobile'}
                    label={<><span style={{ color: "#808080", fontSize: 12, fontWeight: 450 }}>Mobile number</span></>}
                    rules={[{ required: true, message: 'Please enter your mobile number' }, {
                        validator: (_, value) => {
                            console.log("valueee", value);
                            if (!value || value.length === 10) {
                                return Promise.resolve();
                            }
                            if (value.includes(".")) {
                                return Promise.reject(new Error('Mobile number must be digits'));
                            }
                            return Promise.reject(new Error('Mobile number must be exactly 10 digits'));
                        }
                    }]}
                >
                    <Input
                        disabled={userG?.phone}
                        minLength={10}
                        maxLength={10}
                        type="number"

                        // onKeyPress={(event) => {
                        //     if (!/[0-9]/.test(event.key)) {
                        //         event.preventDefault();
                        //     }
                        // }}
                        placeholder="Mobile number"
                        // value={mobile}
                        onChange={(e) => {

                            // const reg = /^-?(0|[1-9][0-9]*)(\.[0-9]*)?$/;
                            // if ((!Number.isNaN(value) && reg.test(value)) || value === '' ) {
                            setMobile(e.target.value)
                        }}
                    //    }

                    />
                </Form.Item>
                <Form.Item>
                    {!loader &&
                        <Button

                            htmlType='submit'
                            onClick={() => {




                            }}
                            // disabled={isFilled(name, email, mobile)}
                            style={{ backgroundColor: "#6BAFFF", width: "100%", fontSize: 12, borderRadius: 5, color: "white" }}
                        >
                            Ask to Join
                        </Button>}
                        {
                                            loader ? <Spin indicator={<LoadingOutlined style={{ color: "black" }}></LoadingOutlined>}></Spin> : <></>

                                        }
                        {loader&&<div style={{fontSize:14, fontWeight:400,color:"#5E5E5E", marginTop:10,}}>
                                   {candidateEmail?"Joining as a candidate in a moment!":" Someone will let you join, Please wait a moment...."}
                                    </div>}
                </Form.Item>
            </Form>
        </div>
    );
};

export default AskToJoinModal;